import React from 'react';
import logo from './logo.svg';
import './contact.css';
import { Social } from '../components/social';

function updateDivDisplay() {
    var radios = document.getElementsByName('apprelated');
    for (var i = 0, length = radios.length; i < length; i++) {
        let radio = radios[i] as HTMLInputElement
        if (radio.checked) {
            // do whatever you want with the checked radio
            
            let appRelated = document.getElementById("app-related")
            if (appRelated) {
                if (radio.value == "yes") {
                    appRelated.style.display = "block";
                }
                else {
                    appRelated.style.display = "none";
                }
            }
            // only one radio can be logically checked, don't check the rest
            break;
        }
    }
    
    radios = document.getElementsByName('bugrelated');
    for (var i = 0, length = radios.length; i < length; i++) {
        let radio = radios[i] as HTMLInputElement
        if (radio.checked) {
            // do whatever you want with the checked radio
            
            let bugRelated = document.getElementById("bug-related")
            if (bugRelated) {
                if (radio.value == "yes") {
                    bugRelated.style.display = "block";
                }
                else {
                    bugRelated.style.display = "none";
                }
            }
            // only one radio can be logically checked, don't check the rest
            break;
        }
    }
}

export function Contact() {
    const error_message: string | undefined = undefined

    const errorMessage = (message: string | undefined): JSX.Element | undefined  => {
        if (!message) {
            return undefined
        }
        return (<div className="block">
            <p style={{textAlign:'center', color:'#ff0000 !important'}}><strong>{ message }</strong></p>
        </div>)
    }

  return (
    <div className="App">
        <div className="block">
            <h2><strong>CONTACT ME</strong></h2>
        </div>
        <div className="column-three-fourth">
            <div className="block">
                Thanks for your interest in contacting me. I will read and consider every email, however, I am unable to
                respond to them all. I appreciate your understanding.
                <br/><br/>Please use the following form to send me your comments.
            </div>
        </div>
        <div className="column-fourth">
            <div className="block" style={{textAlign:'center'}}>
                <strong>Name:</strong> <span className="nowrap">Daniel Jackson</span><br/>
                <strong>Location:</strong> <span className="nowrap">Woodland, CA</span><br/>
                <strong>Twitter:</strong> <span className="nowrap">@DanmJacks</span><br/>
            </div>
            <div className="block" style={{textAlign:'center'}}>
                <Social />
            </div>
        </div>
        <hr />
        
        { errorMessage(error_message) }

        <div className="block" style={{width:'90%', marginLeft: 'auto', marginRight: 'auto'}}>
            <form action="{% url 'website:contact-send' %}" method="post">
                <div className="column-half">
                    <div className="block">
                        Name *<br/>
                        <label>
                            <input type="text" name="first-name" className="field-element"/>
                            First Name
                        </label>
                    </div>
                    
                </div><div className="column-half">
                    &nbsp;<br/>
                    <div className="block">
                        <label>
                            <input type="text" name="last-name" className="field-element"/>
                            Last Name
                        </label>
                    </div>
                </div>
                
                <div className="block">
                    <p>
                    Email *<input type="text" name="email" className="field-element" />
                    </p>
                </div>
                <div className="block">
                    <p>
                    Is this inquiry related to an app/product? *<br/>
                    <input type="radio" name="apprelated" value="yes" onClick={updateDivDisplay} /> Yes
                    <input type="radio" name="apprelated" value="no" onClick={updateDivDisplay} /> No
                    </p>
                </div>
                
                <div id="app-related" style={{display:'none'}}>
                    <hr />
                    <div className="block">
                        <p>
                        What platform are you using? *<br/>
                        <select name="device">
                            <option value='' selected>Please Select</option>
                            <option value="iPhone">iPhone</option>
                            <option value="iPad">iPad</option>
                            <option value="OSX">OSX</option>
                            <option value="Web">Web</option>
                        </select>
                        </p>
                    </div>
                    <div className="block">
                        <p>
                        What OS version are you running? *<br/>
                        <select name="ios-version">
                            <option value='' selected>Please Select</option>
                            <option value="N/A">Not Applicable</option>
                            <option value="iOS 9.*">iOS 9.*</option>
                            <option value="iOS 8.*">iOS 8.*</option>
                            <option value="iOS 7.*">iOS 7.*</option>
                            <option value="iOS 6.*">iOS 6.*</option>
                            <option value="OSX 10.11.*">OSX 10.11.*</option>
                            <option value="OSX 10.10.*">OSX 10.10.*</option>
                            <option value="OSX 10.9.*">OSX 10.9.*</option>
                            <option value="OSX 10.8.*">OSX 10.8.*</option>
                            <option value="OSX 10.7.*">OSX 10.7.*</option>
                            <option value="OSX 10.6.*">OSX 10.6.*</option>
                        </select>
                        </p>
                    </div>
                    <div className="block">
                        <p>
                            Are you having problem(s)? *<br/>
                            <input type="radio" name="bugrelated" value="yes" onClick={updateDivDisplay} /> Yes
                            <input type="radio" name="bugrelated" value="no" onClick={updateDivDisplay} /> No
                        </p>
                    </div>
                    <div id="bug-related"  style={{display:'none'}}>
                        <div className="block">
                            <p/>
                            How can I reproduce your problem? (optional)<br/>
                            <textarea id="reproduce" name="reproduce" className="field-element" rows={5} cols={30} placeholder="Step 1: ..."></textarea>
                        </div>
                    </div>
                    
                    <hr />
                </div>
                
                <div className="block">
                    <p>
                    Message *<br/>
                    <textarea name="message" className="field-element" rows={10} cols={30}></textarea>
                    </p>
                </div>
                
                <div style={{textAlign:'center'}}>
                    <input type="submit" />
                </div>
            </form>
        </div>
    </div>
  );
}