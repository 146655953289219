import React from 'react';
import './index.css';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    NavLink,
} from "react-router-dom";
import { Home } from './pages/home';
import { Projects } from './pages/projects';
import { Contact } from './pages/contact';

export enum Page {
    home = "home", 
    projects = "projects", 
    blog = "blog", 
    contact = "contact"
}

export function createUrl(path: string): string {
    return process.env.PUBLIC_URL + path
}

export function createPageUrl(page: Page): string {
    switch (page) {
        case Page.home: return createUrl("/")
        case Page.projects: return createUrl("/projects")
        case Page.contact: return createUrl("/contact")
        case Page.blog: return createUrl("/blog")
        default: '/'
    }
    return 'error'
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const classNameFunc = (props: { isActive: Boolean }) => ( props.isActive ? "active" : "" );

root.render(
  <React.StrictMode>
      <div id="content">
          <header>
              <div id="header">
                  <h1>Dan Jackson</h1>
              </div>
          </header>
          <Router>
              <div id="navContainer">
                  <nav>
                      <ul id="nav">
                          <li>
                              <NavLink className={classNameFunc} to={`${process.env.PUBLIC_URL}/`}>Home</NavLink>
                              <NavLink className={classNameFunc} to={`${process.env.PUBLIC_URL}/${Page.projects}`}>Projects</NavLink>
                              <NavLink className={classNameFunc} to={`${process.env.PUBLIC_URL}/${Page.contact}`}>Contact</NavLink>
                          </li>
                      </ul>
                  </nav>
              </div>
              <Routes>
                  <Route
                      path="/"
                      element={<Home />}
                  ></Route>
                  <Route
                      path="/projects"
                      element={<Projects />}
                  ></Route>
                  <Route
                      path="/contact"
                      element={<Contact />}
                  ></Route>
              </Routes>
          </Router>
      </div>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();