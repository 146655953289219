import React from 'react';
import logo from './logo.svg';
import './projects.css';
import { createUrl } from '..';

export function Projects() {
  return (
    <div className="App">
      <div id="SectionTitle" className="block">
        <h2><strong>PAST PROJECTS</strong></h2>
      </div>
        
      <hr />
        
      <div className="block app-showcase">
          <div className="showcase-third">
              <a href="{% url 'website:page' 'cloud-play' %}"><img src={createUrl('/images/cloudplayicon.png')} alt="Cloud Play app icon."/></a>
          </div>
          <div className="showcase-twothird">
              <h2>Cloud Play</h2>
              The perfect way to listen to your collection on iOS. Cloud Play is designed as a Google Music streamer for your iPad or iPhone!
              <br/>
              <a href="{% url 'website:page' 'cloud-play' %}">Learn More</a>
          </div>
      </div>
      <div className="block app-showcase">
          <div className="showcase-third">
              <a href="{% url 'website:page' 'twit-demand' %}"><img src={createUrl('/images/twiticon.png')} alt="TWiT On Demand app icon"/></a>
          </div>
          <div className="showcase-twothird">
              <h2>TWiT On Demand</h2>
              Get your podcasts from all of your favorite TWiT shows. Stream shows on demand or choose to download them.
              <br/>
              <a href="{% url 'website:page' 'twit-demand' %}">Learn More</a>
          </div>
      </div>
        
      <br/>
      <br/>
      <br/>
        
      <div id="SectionTitle" className="block">
          <h2><strong>OPEN SOURCE PROJECTS</strong></h2>
      </div>
        
        <hr />
        
      <div className="block">
          <h2>SQLFetchedResultsController</h2>
          <p>
          An attempt at making it easier to setup tables with SQLite. There arent many examples
          of how to properly page through results in a database. I want to fix this. For those
          that enjoy the flexibility that SQL has to offer but dont want to give up the ease of
          setting up tables that you would get with Core Data's NSFetchedResultsController, this
          class is for you.
          </p>
      </div>
      <br/>
      <div className="inline-block">
          <a href="https://github.com/danielmj/SQLFetchedResultsController" target="_blank"><div className="roundedButton" style={{width:'120px'}}>View On Github</div></a>
      </div>
        
      <hr />
        
      <div className="block">
          <h2>DJProgressHUD</h2>
          <p>
          <div className="wrapped-image" style={{maxWidth:'150px !important', marginBottom:'15px'}}>
              <a id="popup-image" href="{% static 'images/DJProgressHUD.png' %}">
                  <img src={createUrl('/images/DJProgressHUD.png')} alt="DJProgressHUD Screenshot" style={{width:'100%'}}/>
              </a>
          </div>
          Progress and Activity HUD for OSX. When I started my first osx app, I noticed that there
          was not good alternative to SVProgressHUD for the mac. Because this is a such a great
          tool for displaying a process, I decided to write it for the mac.
          </p>
      </div>
        <br/>
        <div className="inline-block">
            <a href="https://github.com/danielmj/DJProgressHUD_OSX" target="_blank"><div className="roundedButton" style={{width:'120px'}}>View On Github</div></a>
        </div>
        
        <hr />
        
        <div className="block">
            <h2>AEAudioPlayer</h2>
            <p>
            AEAudioPlayer is an AVAudioEngine music player to mimic AVAudioPlayer. Simply add
            AEAudioplayer.h and AEAudioPlayer.m to your project and use it the same way that you
            use any AVAudioPlayer. To set frequencies, simply initialize AEAudioPlayer with and
            array of NSNumbers with the frequencies that you would like to use. Then call the
            setGain: method to set the gain for that frequency.
            
            </p>
            
        </div>
        <br/>
        <div className="inline-block">
            <a href="https://github.com/danielmj/AEAudioPlayer" target="_blank"><div className="roundedButton" style={{width:'120px'}}>View On Github</div></a>
        </div>
        
        <br/><br/><br/>
        
        
        <div id="SectionTitle" className="block">
            <h2><strong>WEBSITE PROJECTS</strong></h2>
        </div>
        
        <hr />
        
        <div className="block">
            <h2>Django Website</h2>
            <p>
            In the name of exploring new programming languages and new frameworks, I have built this
            entire website on Django with automatic deployment. Previously the website was hosted by
            Squarespace, which has an amazingly easy to use, all-in-one package for building websites.
            However, Squarespace is far too limiting for a developer to build any cool apps. Switching
            to Django means that the site will be relatively easy to maintain and quick to build
            out new features. Furthermore, Django has opened up a world of possibility for integration
            with mobile applications. I can now quickly build an api that utilizes a database or
            other web technologies such as Redis. Im excited for the opportunities that switching to
            Django has given me.
            </p>
        </div>
        
        <hr />
        
        <div className="block">
            <h2>Website for Contractor Services Group, Inc</h2>
            <p>
            This website was designed around CSG's company logo and colors, included a animating 
            slideshow banner, a mini gallery on each page showcasing work, and a custom built 
            content management system. The content management system was programmed using PHP and 
            allowed for the ability to edit nearly every aspect of each page. It offered a HTML/PHP 
            editor to modify page content on the fly, as well as a user friendly way to upload 
            additional documents to pages and rearrange photos in the mini galleries. Additionally, 
            the CMS provided a means to offer clients a way to share files. It included a user 
            management system and a group management system where groups were given storage space 
            for files with manageable permissions.
            <br/><br/>
            <strong>EDIT:</strong> The PHP/MySQL CMS portal is no longer in operation.
            
            </p>
        </div>
        <br/>
        <div className="inline-block">
            <a href="https://www.csg-web.com/" target="_blank"><div className="roundedButton" style={{width:'80px'}}>View Site</div></a>
        </div>
    </div>
  );
}