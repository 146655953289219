import React from 'react';
import logo from './logo.svg';
import './home.css';
import { Social } from '../components/social';
import { createUrl } from '..';

export function Home() {
  return (
    <div className="App">
      <div className="block column-fourth" style={{ marginBottom: '35px', padding:0 }}>
          <div className="block">
              <p style={{textAlign: 'center' }}>
                <img src={createUrl("/images/portrait_3.jpg")} alt="Profile picture" style={{ width:'100%', borderRadius:'50%', maxWidth:'175px'}} />
              </p>
          </div>
          <div className="block" style={{textAlign:'center'}}>
              <Social />
          </div>
      </div>
      <div className="block column-three-fourth" style={{marginBottom:'35px', padding: 0, width: '67%', marginLeft: '3%'}}>
          <p>
          I typically find myself working on one or more self generated projects at time with languages such as
          Swift, Objective-C, C++, Python, Java, PHP, JS, and many more. Many of my recent projects involve programming on iOS and OSX--check some of the
          them out below!
          </p>
          <br />
          <p>
          Check out the <a href={createUrl("/blog")}>blog</a> for any updates on the status of the projects listed below. If you have questions
          or comments, feel free to use the <a href={createUrl("/contact")}>contact form</a>.
          </p>
      </div>
    </div>
  );
}