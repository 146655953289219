import React, { CSSProperties } from 'react';
import { createUrl } from '..';

export function Social() {
    let paragraphStyle: CSSProperties = {textAlign:'center'}
    let blockStyle: CSSProperties = {marginLeft: '5px'}

  return (
    <div className="nowrap">
        <div className="inline-block">
            <p style={paragraphStyle}>
            <a href="http://www.twitter.com/@danmjacks" target="_blank"><img src={createUrl("/images/social/twitter.png")} alt="Twitter profile" width="20" /></a>
            </p>
        </div>
        <div className="inline-block" style={blockStyle}>
            <p style={paragraphStyle}>
            <a href="http://www.github.com/danielmj" target="_blank"><img src={createUrl("/images/social/github.png")} alt="Github profile" width="20" /></a>
            </p>
        </div>
        <div className="inline-block" style={blockStyle}>
            <p style={paragraphStyle}>
            <a href="https://www.linkedin.com/pub/daniel-jackson/77/2b/14" target="_blank"><img src={createUrl("/images/social/linkedin.png")} width="20" alt="Linked In profile" /></a>
            </p>
        </div>
        <div className="inline-block" style={blockStyle}>
            <p style={paragraphStyle}>
            <a href="https://plus.google.com/u/0/104156470079999071503/" rel="Publisher" target="_blank"><img src={createUrl("/images/social/googleplus.png")} width="20" alt="Google+ profile" /></a>
            </p>
        </div>
    </div>
  );
}